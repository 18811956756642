<template>
	<div class="resultBox">
		<div class="results">
			<div v-if="orderId">
				<p v-if="loading"><van-loading /></p>
				<p v-else-if="result">
					<van-icon :name="result == 'SUCCESS' ? `passed` : `close`" size="46" :color="result == 'SUCCESS' ? `#07C160` : `#ee0a24`" />
				</p>
				<p style="margin-top: 0.625rem;">{{result || $t('common.loading')}}</p>
				<p v-if="result == 'SUCCESS'" style="margin-top: 0.625rem;padding: 0.3125rem 1rem;font-size: 0.75rem;">결제 성공했습니다~ 인게임으로 이동하여 확인해주시길 바라며 오류나 문의 있을 시 인게임 고객 서비스 센터로 문의하시길 바랍니다!</p>
				<div style="margin-top: 0.625rem;width: 100%;display: flex;justify-content: center;" v-if="showClose">
					<a style="width: 80px;height: 20px;text-align: center;text-decoration: underline;line-height: 20px;" @click="closed">닫기</a>
				</div>
			</div>
			<p v-else>ERROR</p>
		</div>
	</div>
</template>

<script setup>
	import { getCurrentInstance,onMounted,onBeforeMount,ref,watch,computed } from 'vue'
	import { useRoute,useRouter } from 'vue-router';
	import { getOrder } from "@/api/index";
	
	const { proxy } = getCurrentInstance();
	
	// Import the functions you need from the SDKs you need
	import { initializeApp } from "firebase/app";
	import { getAnalytics, logEvent } from "firebase/analytics";
	// Initialize Firebase
	const app = initializeApp(proxy.partten.firebaseConfig);
	const analytics = getAnalytics(app);
	
	const router = useRouter();
	const result = ref('')
	const orderId = ref('')
	const loading = ref(false)
	const showClose = ref(false)
	onMounted(() => {
		const query = router.currentRoute.value.params
		if(query.id){
			console.log(query)
			orderId.value = query.id
			loading.value = false
			getOrder(query.id).then((res) => {
				console.log(res)
				// SUCCESS 交易成功 
				// PENDING 交易处理中 
				// FAILED 交易失败 
				// CLOSED 交易关单 
				result.value = res.msg
				loading.value = false
				let status = res.msg == 'SUCCESS' ? 'pay_success' : 'pay_fail'
				if(query.source){
					// logEvent(analytics, 'h5pay_success', { des: orderId.value })
					showClose.value = true
				}else{
					proxy.sdkPay(status,res.msg)
				}
			}).catch((err)=>{
				result.value = err.msg
				loading.value = false
				if(query.source){
					showClose.value = true
				}else{
					proxy.sdkPay('pay_fail',err.msg)
				}
			})
		}
	})
	const closed = () => {
		window.close()
		console.log('关闭当前页面')
	}
</script>

<style lang="less" scoped>
	.resultBox{
		position: relative;width: 100%;height: 100vh;
		.results{
			width: 100%;position: absolute;left: 0;top: 50%;transform: translate(0,-50%);
			p{
				text-align: center;
			}
		}
	}
</style>